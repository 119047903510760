import { Container, Image } from "react-bootstrap";
import "./PragmaEntry.css";
import SimpleContact from "../Contact/SimpleContact";
const PragmaEntry = (props: any) => {
  const createdAt = new Date(props.created_at);
  const modifiedAt = new Date(props.modified_at);
  return (
    <>
      <Container className="d-flex justify-content-center">
        <Image
          className="pragma_entry__image"
          src={props.image}
          alt={props.title}
          fluid
        />
      </Container>
      <h1>{props.title}</h1>
      <hr />
      <p>
        <b>Created:</b> {createdAt.toString()}
        <br />
        <b>Last modified:</b> {modifiedAt.toString()}
      </p>
      <hr />
      <div dangerouslySetInnerHTML={{ __html: props.content }}></div>
      <hr />
      <Container className="d-flex justify-content-center">
        <div>
          <SimpleContact
            info="Comments, suggestions, find an error? Let us know "
            email="contact@honestpragmatism.com"
          />
        </div>
      </Container>
    </>
  );
};

export default PragmaEntry;
