import { Container } from "react-bootstrap";

const SimpleContact = (props: any) => {
  return (
    <Container>
      <div className="simplecontact_a">
        <p>
          {props.info}
          <a href={`mailto: ${props.email}`}>{props.email}</a>
        </p>
      </div>
    </Container>
  );
};

export default SimpleContact;
