import "./SimpleContact.css";
import SimpleContactComponent from "../components/Contact/SimpleContact";

const SimpleContact = () => {
  return (
    <>
      <SimpleContactComponent
        info="Contact for inquiring about and hiring services: "
        email="contact@honestpragmatism.com"
      />
    </>
  );
};

export default SimpleContact;
