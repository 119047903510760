import { Container, Row } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';

import "./Pragmas.css";
import PragmaCard from "../components/Pragma/PragmaCard";
import { useEffect, useState } from "react";
import Card from "../components/Card/Card";

const Pragmas = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const sendRequestWrapper = async () => {
      setIsLoading(true);
      try {
        const url = `${process.env.REACT_APP_FA_BACKEND_URL}/blogs/`;
        const res = await fetch(url, {
          method: "GET",
        });
        const data = await res.json();
        setBlogs(data);
        setIsLoading(false);
      } catch (error) {
        alert(error);
        setIsLoading(false);
      }
    };
    sendRequestWrapper();
    
  }, []);

  if (isLoading) {
    return (
      <>
        <Container className="pragma__container">
          <Row className="justify-content-center">
            <Spinner animation="border" variant="primary" />
            <Spinner animation="border" variant="success" />
          </Row>
          <Row className="justify-content-center">
            <Spinner animation="border" variant="danger" />
            <Spinner animation="border" variant="warning" />
          </Row>
        </Container>
      </>
    );
  }

  if (!blogs.length && !isLoading) {
    return (
      <>
        <Container className="pragma__container">
          <Row className="justify-content-center">
            <Card
              title="No blogs found"
              text="Please wait for someone to write a blog."
            />
          </Row>
        </Container>
      </>
    );
  }

  return (
    <>
      <Container className="pragma__container">
        <Row className="justify-content-center">
          {blogs.map((blog) => (
            <PragmaCard
              key={blog["id"]}
              id={blog["id"]}
              title={blog["title"]}
              description={blog["description"]}
              image={blog["image"]}
            />
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Pragmas;
