import { Card as BCard } from "react-bootstrap";

const ImageCard = (props: any) => {
  return (
    <BCard className="border-0 m-2 p-1" style={{ width: "7rem" }}>
      <BCard.Img variant="top" src={props.image} />
    </BCard>
  );
};

export default ImageCard;
