import logo from "../hp_logo.png";

import "./Home.css";

const Home = () => {
  return (
    <>
      <img
        className="home__img"
        src={logo}
        alt="Honest Pragmatism home page logo"
      ></img>
    </>
  );
};

export default Home;
