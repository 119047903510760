import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navigation from "./shared/components/Navigation/Navigation";
import Home from "./pages/Home";
import Contact from "./pages/Contact";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Services from "./pages/Services";
import SimpleContact from "./pages/SimpleContact";
import Pragmas from "./pages/Pragmas";
import Pragma from "./pages/Pragma";

function App() {
  return (
    <BrowserRouter>
      <Navigation />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<SimpleContact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/pragmas/:id" element={<Pragma />} />
          <Route path="/pragmas" element={<Pragmas />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
}

export default App;
