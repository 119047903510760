import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Services.css";
import Card from "../components/Card/Card";

import python_icon from "../images/python-logo-only.png";
import node_img from "../images/nodejsStackedDark.svg";
import java_img from "../images/java_icon.png";
import ts_img from "../images/typescript.png";
import django_img from "../images/django.png";
import flask_img from "../images/flask.png";
import react_img from "../images/reactjs.png";
import angular_img from "../images/angular.png";
import aws_img from "../images/aws.png";
import js_img from "../images/js.png";
import gcp_img from "../images/gcp.png";
import do_img from "../images/digitalocean.png";
import heroku_img from "../images/heroku.svg";
import docker_img from "../images/docker.png";
import ubuntu_img from "../images/ubuntu.png";
import k8s_img from "../images/k8s.png";
import mongo_img from "../images/mongo.png";
import postgres_img from "../images/postgres.png";
import github_img from "../images/github.png";
import fastapi_img from "../images/fastapi_logo.png";

import ImageCard from "../components/Card/ImageCard";

const Services = () => {
  return (
    <>
      <Container className="services__container">
        <div>
          <h2>Services</h2>
          <Row>
            <Col>
              <Row>
                <Col>
                  <Card title="Software Development" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card title="Cloud Architecture" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card title="System Design" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card title="Scripting: Python/Bash" />
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Card title="API Design" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card title="CI/CD" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card title="Systems Integration" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card title="Consulting" />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
      <Container className="services__container">
        <h2>Technologies</h2>
        <Row>
          <Col>
            <ImageCard image={python_icon} />
          </Col>
          <Col>
            <ImageCard image={node_img} />
          </Col>
          <Col>
            <ImageCard image={java_img} />
          </Col>
          <Col>
            <ImageCard image={ts_img} />
          </Col>
          <Col>
            <ImageCard image={js_img} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ImageCard image={django_img} />
          </Col>
          <Col>
            <ImageCard image={flask_img} />
          </Col>
          <Col>
            <ImageCard image={react_img} />
          </Col>
          <Col>
            <ImageCard image={angular_img} />
          </Col>
          <Col>
            <ImageCard image={fastapi_img} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ImageCard image={aws_img} />
          </Col>
          <Col>
            <ImageCard image={gcp_img} />
          </Col>
          <Col>
            <ImageCard image={do_img} />
          </Col>
          <Col>
            <ImageCard image={heroku_img} />
          </Col>
          <Col>
            <ImageCard image={github_img} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ImageCard image={docker_img} />
          </Col>
          <Col>
            <ImageCard image={ubuntu_img} />
          </Col>
          <Col>
            <ImageCard image={k8s_img} />
          </Col>
          <Col>
            <ImageCard image={mongo_img} />
          </Col>
          <Col>
            <ImageCard image={postgres_img} />
          </Col>
        </Row>
      </Container>
      <Container className="services__container">
        <div className="simplecontact_a">
          <p>
            Interested in services or technologies not on the list? Send over an
            email and maybe we can help:{" "}
            <a href="mailto: contact@honestpragmatism.com">
              contact@honestpragmatism.com
            </a>
          </p>
        </div>
      </Container>
    </>
  );
};

export default Services;
