import { Card as BCard } from "react-bootstrap";

const Card = (props: any) => {
  return (
    <BCard className="border-0" style={{ width: "18rem" }}>
      <BCard.Body>
        <BCard.Title>{props.title}</BCard.Title>
        <BCard.Text>{props.text}</BCard.Text>
      </BCard.Body>
    </BCard>
  );
};

export default Card;
