import { Link } from "react-router-dom";

import "./Navigation.css";
import logo from "../../../hp_logo_small.png";
import NavLinks from "./NavLinks";
import { useState } from "react";
import Backdrop from "../UIElements/Backdrop";
import SideDrawer from "./SideDrawer";

const Navigation = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawer = () => {
    setDrawerIsOpen(true);
  };
  const closeDrawer = () => {
    setDrawerIsOpen(false);
  };
  return (
    <header className="navigation__header">
      {drawerIsOpen && <Backdrop onClick={closeDrawer} />}
      {drawerIsOpen && (
        <SideDrawer show={drawerIsOpen} onClick={closeDrawer}>
          <nav>
            <NavLinks showHome={drawerIsOpen} />
          </nav>
        </SideDrawer>
      )}
      <button className="navigation__menu-btn" onClick={openDrawer}>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <Link to="/">
        <img className="navigation__logo" src={logo} alt="Small logo"></img>
      </Link>
      <nav className="navigation__header-nav">
        <NavLinks showHome={drawerIsOpen} />
      </nav>
    </header>
  );
};

export default Navigation;
