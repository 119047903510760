import { NavLink } from "react-router-dom";

import logo from "../../../hp_logo_small.png";

import "./NavLinks.css";

const NavLinks = (props: any) => {
  return (
    <ul className="navlinks__list">
      {props.showHome && (
        <NavLink to="/">
          <img className="navlinks__logo" src={logo} alt="Small logo"></img>
        </NavLink>
      )}
      <li>
        <NavLink to="/pragmas">Pragma</NavLink>
      </li>
      {/* <li>
        <NavLink to="/services">Services</NavLink>
      </li> */}
      {/* <li>
        <NavLink to="/contact">Contact</NavLink>
      </li> */}
    </ul>
  );
};

export default NavLinks;
