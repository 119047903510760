import { Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import hp_logo from "../../hp_logo_small.png";
import "./PragmaCard.css";

const PragmaCard = (props: any) => {
  const image: any = () => {
    return props.image ? props.image : hp_logo;
  };
  return (
    <Card className="m-2" style={{ width: "18rem" }}>
      <NavLink className="pragma_card__a" to={`/pragmas/${props.id}`}>
        <Card.Img
          className="p-3"
          variant="top"
          src={image()}
          alt={props.title}
        />
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text>{props.description}</Card.Text>
        </Card.Body>
      </NavLink>
    </Card>
  );
};

export default PragmaCard;
