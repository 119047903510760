import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import PragmaEntry from "../components/Pragma/PragmaEntry";
import { Container, Row, Spinner } from "react-bootstrap";

import "./Pragma.css";

const Pragma = () => {
  const params = useParams();
  const iD = params.id;
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null!);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const sendRequestWrapper = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(
          `${process.env.REACT_APP_FA_BACKEND_URL}/blogs/${iD}`,
          {
            method: "GET",
          },
        );
        const data = await res.json();
        setBlog(data);
        setIsLoading(false);
      } catch (error) {
        alert(error);
        setIsLoading(false);
      }
    };
    sendRequestWrapper();
  }, [iD]);

  if (isLoading) {
    return (
      <>
        <Container className="pragma__container">
          <Row className="justify-content-center">
            <Spinner animation="border" variant="primary" />
            <Spinner animation="border" variant="success" />
          </Row>
          <Row className="justify-content-center">
            <Spinner animation="border" variant="danger" />
            <Spinner animation="border" variant="warning" />
          </Row>
        </Container>
      </>
    );
  }

  return (
    <>
      {blog && (
        <Container className="pragma__container">
          <PragmaEntry
            title={blog["title"]}
            content={blog["content"]}
            image={blog["image"]}
            modified_at={blog["modified_at"]}
            created_at={blog["created_at"]}
          />
        </Container>
      )}
    </>
  );
};

export default Pragma;
